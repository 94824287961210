import React from 'react';
import styled from 'styled-components';
import { useCurrentUser, usePromotions } from '../../hooks/user';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { logMetricsTrackingEvent } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, Body3 } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { LinkBankPlaid } from './LinkBankPlaid';
import InfoIcon from '../../../containers/UI/InfoIcon';
import FundingBonusBannerRenderer from '../../../components/banners/FundingBonusBannerRenderer';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_NAME } from '@src/appConfig';

const ContentWrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const BannerStyle = styled.div`
  margin: 24px auto 0 auto;
  padding: 16px;
  border-radius: 5px;
  border: 1px dashed ${({ theme }) => theme.themeColors.text};
`;
const Actions = styled.div`
  padding-top: 8px;
  text-align: center;
`;
const ButtonText = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;
const SubButtonText = styled.div`
  * {
    font-size: 10px !important;
  }
`;

export const LinkBankAccount = ({ onContinue, onSkip, onPathChange, onboardingState }) => {
  const currentUser = useCurrentUser();
  const bankAccounts = useBankAccounts();
  const { isShowingFundingPromo } = usePromotions();

  const hasLinkedAlready = bankAccounts.length > 0;

  const [isReady, setIsReady] = React.useState(true);
  const [showLinkDifferentAccount, setShowLinkDifferentAccount] = React.useState(false);

  const [showLinkBankOptions] = React.useState(true);

  const [showPlaidLink, setShowPlaidLink] = React.useState(false);
  // const [showMicroLink, setShowMicroLink] = React.useState(false);

  const onPlaidContinue = React.useCallback(async (didLink) => {
    if (didLink) {
      return onContinue(null, onboardingState);
    } else {
      setShowPlaidLink(false);
    }
  }, []);

  const handleContinue = React.useCallback(async (didLink) => {
    return onContinue(null, onboardingState);
  }, []);

  const handleSkip = React.useCallback(async () => {
    onSkip(null, onboardingState);
  }, []);

  const handleLinkDifferentAccount = React.useCallback(async () => {
    setShowLinkDifferentAccount(true);
  }, []);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Live Account Application Submitted Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  if (showPlaidLink) {
    return (
      <LinkBankPlaid onPathChange={onPathChange} onPlaidContinue={onPlaidContinue} onboardingState={onboardingState} />
    );
  }

  // if (showMicroLink) {
  //   return <LinkBankMicroDeposits onContinue={handleContinue} onboardingState={onboardingState} />;
  // }

  if (hasLinkedAlready) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.linkBankAccountMethodSelection}>
        <Page width={'460px'}>
          <ContentWrapper>
            {showLinkDifferentAccount ? (
              <>
                <Heading>
                  <H5>Let's link a bank account</H5>
                </Heading>

                <div style={{ paddingTop: '8px' }}>
                  <Body5>
                    {`Connect a bank account to deposit funds and start investing with ${PRODUCT_NAME}.`}
                    <InfoIcon
                      id={'funding_see_tos_tooltip'}
                      word={'tooltip_funding_see_tos'}
                      position={'right'}
                      style={{ position: 'relative', top: '1px', right: '-4px' }}
                    />
                  </Body5>
                </div>
              </>
            ) : (
              <>
                <Heading>
                  <H5>Bank Account Linked</H5>
                </Heading>

                <div style={{ paddingTop: '8px' }}>
                  <Body5>
                    {`You have already linked a bank account, let\'s add funding and start investing with ${PRODUCT_NAME}.`}
                    <InfoIcon
                      id={'funding_see_tos_tooltip'}
                      word={'tooltip_funding_see_tos'}
                      position={'right'}
                      style={{ position: 'relative', top: '1px', right: '-4px' }}
                    />
                  </Body5>
                </div>
              </>
            )}

            {isShowingFundingPromo && (
              <BannerStyle>
                <FundingBonusBannerRenderer />
              </BannerStyle>
            )}

            <Actions>
              {showLinkDifferentAccount && (
                <>
                  <Message>
                    <FlatButton fullWidth onClick={() => setShowPlaidLink(true)}>
                      <ButtonText>
                        <Body3>Link Instantly</Body3>
                        <SubButtonText>
                          <Body5 isLowContrast>With Bank Account Login</Body5>
                        </SubButtonText>
                      </ButtonText>
                    </FlatButton>
                  </Message>

                  {/*<div style={{ paddingTop: '8px' }}>*/}
                  {/*  <FlatButton transparent fullWidth onClick={() => setShowMicroLink(true)}>*/}
                  {/*    Link With Microdeposits (1 - 2 Business Days)*/}
                  {/*  </FlatButton>*/}
                  {/*</div>*/}
                </>
              )}

              <div style={{ paddingTop: '40px' }}>
                {!showLinkDifferentAccount && (
                  <div style={{ paddingBottom: '8px' }}>
                    <FlatButton transparent fullWidth onClick={handleLinkDifferentAccount}>
                      Link A Different Bank Account
                    </FlatButton>
                  </div>
                )}
                <FlatButton fullWidth onClick={handleContinue}>
                  Continue
                </FlatButton>
                <div style={{ paddingTop: '8px' }}>
                  <FlatButton transparent fullWidth onClick={handleSkip}>
                    Skip
                  </FlatButton>
                </div>
              </div>
            </Actions>
          </ContentWrapper>
        </Page>
      </LocationContext.Provider>
    );
  }

  if (showLinkBankOptions) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.linkBankAccountMethodSelection}>
        <Page width={'460px'}>
          <ContentWrapper>
            <Heading>
              <H5>Link A Bank Account</H5>
            </Heading>

            <div style={{ paddingTop: '8px' }}>
              <Body5>
                {`Connect a bank account to deposit funds and start investing with ${PRODUCT_NAME}.`}
                <InfoIcon
                  id={'funding_see_tos_tooltip'}
                  word={'tooltip_funding_see_tos'}
                  position={'right'}
                  style={{ position: 'relative', top: '1px', right: '-4px' }}
                />
              </Body5>
            </div>

            {isShowingFundingPromo && (
              <div onClick={() => setShowPlaidLink(true)} style={{ cursor: 'pointer' }}>
                <BannerStyle>
                  <FundingBonusBannerRenderer />
                </BannerStyle>
              </div>
            )}

            <Actions>
              <Message>
                <FlatButton fullWidth onClick={() => setShowPlaidLink(true)}>
                  <ButtonText>
                    <Body3>Link Instantly</Body3>
                    <SubButtonText>
                      <Body5 isLowContrast>With Bank Account Login</Body5>
                    </SubButtonText>
                  </ButtonText>
                </FlatButton>
              </Message>

              <div style={{ paddingTop: '40px' }}>
                <FlatButton transparent fullWidth onClick={handleSkip}>
                  Skip
                </FlatButton>
              </div>
            </Actions>
          </ContentWrapper>
        </Page>
      </LocationContext.Provider>
    );
  }
};

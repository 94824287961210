import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import { Page } from '../../components/layout';
import { H3, Body5 } from '../../lib/nvstr-common-ui.es';
import { FlatButton, SkeletonButton } from '../../components/buttons';
import { LinkBankPlaid } from './LinkBankPlaid';
import { LinkBankMicroDeposits } from './interstitial/LinkBankMicroDeposits';
import InfoIcon from '../../../containers/UI/InfoIcon';
import FundingBonusBannerRenderer from '../../../components/banners/FundingBonusBannerRenderer';
import { useCurrentUser, usePromotions } from '../../hooks/user';
import { TrackingEvents } from '../../../utils/tracking/events';
import { Container } from '@src/main/components/ui';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const BannerStyle = styled.div`
  margin: 24px auto 0 auto;
  padding: 16px;
  border-radius: 5px;
  border: 1px dashed ${({ theme }) => theme.themeColors.text};
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;

export const LinkBankAccount = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const { isShowingFundingPromo } = usePromotions();

  const [showLinkWithPlaid, setShowLinkWithPlaid] = React.useState(false);
  const [showLinkWithMicrodeposits, setShowLinkWithMicrodeposits] = React.useState(false);

  const handleLinkInstantlyClick = () => {
    TrackingEvents.funding.CHOOSE_LINK_BANK_ACCOUNT_OPTION.send({
      Mode: 'Plaid',
    });
    setShowLinkWithPlaid(true);
  };

  // const handleLinkMicroDepositsClick = () => {
  //   TrackingEvents.funding.CHOOSE_LINK_BANK_ACCOUNT_OPTION.send({
  //     Mode: 'Microdeposits',
  //   });
  //   setShowLinkWithMicrodeposits(true);
  // };

  const handleLinkInstantlyContinue = (hadLinkSuccess) => {
    if (hadLinkSuccess) {
      navigate(ROUTES.FUNDING.build());
    } else {
      setShowLinkWithPlaid(false);
    }
  };

  const handleLinkMicroDepositsContinue = (hadLinkSuccess) => {
    if (hadLinkSuccess) {
    } else {
      setShowLinkWithMicrodeposits(false);
    }
  };

  if (showLinkWithPlaid) {
    return <LinkBankPlaid onContinue={handleLinkInstantlyContinue} />;
  }

  if (showLinkWithMicrodeposits) {
    return <LinkBankMicroDeposits onContinue={handleLinkMicroDepositsContinue} />;
  }

  // NOTE: add message for disabled bank linking
  // return (
  //   <Page width={'600px'}>
  //     <Content>
  //       <H3>Link Bank Account</H3>
  //       <Container bottom={36}>
  //         <Body5>
  //           The ability to link new bank accounts has been temporarily disabled. We are actively working on a resolution
  //           and hope to have this functionality restored soon.
  //         </Body5>
  //       </Container>
  //     </Content>
  //   </Page>
  // );

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Link Bank Account</H3>
        <div>
          <Body5>Connect a bank account to transfer funds to and from your Tornado account.</Body5>
          <InfoIcon
            id={'funding_see_tos_tooltip'}
            word={'tooltip_funding_see_tos'}
            position={'right'}
            style={{ position: 'relative', top: '1px', right: '-4px' }}
          />
        </div>

        {isShowingFundingPromo && (
          <BannerStyle>
            <FundingBonusBannerRenderer />
          </BannerStyle>
        )}

        <ActionsWrapper>
          <div style={{}}>
            <Container fullWidth centerAll>
              <FlatButton fullWidth onClick={handleLinkInstantlyClick}>
                Link Instantly
              </FlatButton>
            </Container>
            <div style={{ paddingTop: '4px' }}>
              <Body5 isSmall>With Bank Account Login</Body5>
            </div>
          </div>

          {/*<Container top={16} fullWidth centerAll>*/}
          {/*  <SkeletonButton fullWidth onClick={handleLinkMicroDepositsClick}>*/}
          {/*    Link with Microdeposits (1-2 Business Days)*/}
          {/*  </SkeletonButton>*/}
          {/*</Container>*/}
        </ActionsWrapper>

        <div style={{ paddingTop: '24px' }}>
          <Body5 thin>
            This account can be used to transfer funds to and from your Tornado account and to pay Tornado fees.{' '}
          </Body5>
          <Body5 thin>See Terms of Service for details.</Body5>
        </div>
      </Content>
    </Page>
  );
};

import { filterArrayForDuplicates } from '@src/helpers/generalHelpers';
import { BASEURL } from '@src/adapters';
import { formatLocalizedDateTime, replaceAll } from '@src/main/lib/nvstr-utils.es';
import { sendRequest } from '@src/helpers/ajaxHelpers';
import { createTimeInstance } from '@src/helpers/timeHelpers';

class _Helpers {
  constructor() {
    // GENERATE SYMBOL CONVERSION LOOKUPS
    const _SYMBOLS_WITH_HYPHEN = [
      'AAIC.B',
      'AAIC.C',
      'AAM.A',
      'AAM.B',
      'ABR.D',
      'ABR.E',
      'ACP.A',
      'ACR.C',
      'ACR.D',
      'ADC.A',
      'AEL.A',
      'AEL.B',
      'AGM.A',
      'AGM.C',
      'AGM.D',
      'AGM.E',
      'AGM.F',
      'AGM.G',
      'AHH.A',
      'AHL.C',
      'AHL.D',
      'AHL.E',
      'AHT.D',
      'AHT.F',
      'AHT.G',
      'AHT.H',
      'AHT.I',
      'AIG.A',
      'AL.A',
      'ALL.H',
      'ALL.I',
      'ALL.J',
      'ALTG.A',
      'AMH.G',
      'AMH.H',
      'APO.A',
      'ARGO.A',
      'ARR.C',
      'ASB.E',
      'ASB.F',
      'ATCO.D',
      'ATCO.H',
      'ATCO.I',
      'ATH.A',
      'ATH.B',
      'ATH.C',
      'ATH.D',
      'ATH.E',
      'AUB.A',
      'AULT.D',
      'AXS.E',
      'BAC.B',
      'BAC.E',
      'BAC.K',
      'BAC.L',
      'BAC.M',
      'BAC.N',
      'BAC.O',
      'BAC.P',
      'BAC.Q',
      'BAC.S',
      'BCV.A',
      'BEP.A',
      'BFS.D',
      'BFS.E',
      'BHR.B',
      'BHR.D',
      'BIP.A',
      'BIP.B',
      'BML.G',
      'BML.H',
      'BML.J',
      'BML.L',
      'BOH.A',
      'BW.A',
      'CADE.A',
      'CDR.B',
      'CDR.C',
      'CFG.D',
      'CFG.E',
      'CFR.B',
      'CHMI.A',
      'CHMI.B',
      'CIM.A',
      'CIM.B',
      'CIM.C',
      'CIM.D',
      'CIO.A',
      'C.J',
      'C.K',
      'CLDT.A',
      'CLVT.A',
      'CMRE.B',
      'CMRE.C',
      'CMRE.D',
      'CMRE.E',
      'CMS.B',
      'CMS.C',
      'CODI.A',
      'CODI.B',
      'CODI.C',
      'COF.I',
      'COF.J',
      'COF.K',
      'COF.L',
      'COF.N',
      'CORR.A',
      'CSR.C',
      'CTA.A',
      'CTA.B',
      'CTO.A',
      'CUBI.E',
      'CUBI.F',
      'DBRG.H',
      'DBRG.I',
      'DBRG.J',
      'DCP.C',
      'DLNG.A',
      'DLNG.B',
      'DLR.J',
      'DLR.K',
      'DLR.L',
      'DRH.A',
      'DSX.B',
      'DUK.A',
      'DX.C',
      'ECC.D',
      'ECF.A',
      'EFC.A',
      'EFC.B',
      'EFC.C',
      'EPR.C',
      'EPR.E',
      'EPR.G',
      'EQC.D',
      'EQH.A',
      'EQH.C',
      'ET.C',
      'ET.D',
      'ET.E',
      'FBRT.E',
      'FHN.A',
      'FHN.B',
      'FHN.C',
      'FHN.D',
      'FHN.E',
      'FHN.F',
      'FNB.E',
      'FRT.C',
      'GAB.G',
      'GAB.H',
      'GAB.K',
      'GAM.B',
      'GDL.C',
      'GDV.H',
      'GDV.K',
      'GGN.B',
      'GGT.E',
      'GGT.G',
      'GLOG.A',
      'GLOP.A',
      'GLOP.B',
      'GLOP.C',
      'GLP.A',
      'GLP.B',
      'GLU.A',
      'GLU.B',
      'GMRE.A',
      'GNL.A',
      'GNL.B',
      'GNT.A',
      'GPMT.A',
      'GRBK.A',
      'GS.A',
      'GS.C',
      'GS.D',
      'GS.J',
      'GS.K',
      'GSL.B',
      'GTLS.B',
      'GUT.C',
      'HFRO.A',
      'HIG.G',
      'HL.B',
      'HPP.C',
      'HT.C',
      'HT.D',
      'HT.E',
      'ICR.A',
      'IIPR.A',
      'INN.E',
      'INN.F',
      'IVR.B',
      'IVR.C',
      'JPM.C',
      'JPM.D',
      'JPM.J',
      'JPM.K',
      'JPM.L',
      'JPM.M',
      'JXN.A',
      'KEY.I',
      'KEY.J',
      'KEY.K',
      'KEY.L',
      'KIM.L',
      'KKR.C',
      'KREF.A',
      'LFT.A',
      'LNC.D',
      'LXP.C',
      'MAA.I',
      'MDV.A',
      'MER.K',
      'MET.A',
      'MET.E',
      'MET.F',
      'MFA.B',
      'MFA.C',
      'MITT.A',
      'MITT.B',
      'MITT.C',
      'MS.A',
      'MS.E',
      'MS.F',
      'MS.I',
      'MS.K',
      'MS.L',
      'MS.O',
      'MS.P',
      'MTB.H',
      'NCV.A',
      'NCZ.A',
      'NGL.B',
      'NGL.C',
      'NI.B',
      'NLY.F',
      'NLY.G',
      'NLY.I',
      'NM.G',
      'NMK.B',
      'NMK.C',
      'NREF.A',
      'NS.A',
      'NSA.A',
      'NS.B',
      'NS.C',
      'NXDT.A',
      'NYCB.A',
      'NYCB.U',
      'OAK.A',
      'OAK.B',
      'OPP.A',
      'OPP.B',
      'PCG.A',
      'PCG.B',
      'PCG.C',
      'PCG.D',
      'PCG.E',
      'PCG.G',
      'PCG.H',
      'PCG.I',
      'PEB.E',
      'PEB.F',
      'PEB.G',
      'PEB.H',
      'PLYM.A',
      'PMT.A',
      'PMT.B',
      'PMT.C',
      'PRE.J',
      'PRIF.D',
      'PRIF.F',
      'PRIF.G',
      'PRIF.H',
      'PRIF.I',
      'PRIF.J',
      'PRIF.K',
      'PRIF.L',
      'PSA.F',
      'PSA.G',
      'PSA.H',
      'PSA.I',
      'PSA.J',
      'PSA.K',
      'PSA.L',
      'PSA.M',
      'PSA.N',
      'PSA.O',
      'PSA.P',
      'PSA.Q',
      'PSA.R',
      'PSA.S',
      'PSEC.A',
      'PW.A',
      'RC.C',
      'RC.E',
      'REXR.B',
      'REXR.C',
      'RF.B',
      'RF.C',
      'RF.E',
      'RHE.A',
      'RITM.A',
      'RITM.B',
      'RITM.C',
      'RITM.D',
      'RIV.A',
      'RJF.B',
      'RLJ.A',
      'RNR.F',
      'RNR.G',
      'RPT.D',
      'RWT.A',
      'RY.T',
      'SACH.A',
      'SB.C',
      'SB.D',
      'SCE.G',
      'SCE.H',
      'SCE.J',
      'SCE.K',
      'SCE.L',
      'SCHW.D',
      'SCHW.J',
      'SEAL.A',
      'SEAL.B',
      'SF.B',
      'SF.C',
      'SF.D',
      'SHO.H',
      'SHO.I',
      'SITC.A',
      'SLG.I',
      'SNV.D',
      'SNV.E',
      'SPE.C',
      'SPG.J',
      'SPLP.A',
      'SPNT.B',
      'SR.A',
      'SRC.A',
      'SRG.A',
      'STT.D',
      'STT.G',
      'SYF.A',
      'T.A',
      'T.C',
      'TDS.U',
      'TDS.V',
      'TFC.I',
      'TFC.O',
      'TFC.R',
      'TGH.A',
      'TGH.B',
      'TNP.E',
      'TNP.F',
      'TRTN.A',
      'TRTN.B',
      'TRTN.C',
      'TRTN.D',
      'TRTN.E',
      'TRTX.C',
      'TWO.A',
      'TWO.B',
      'TWO.C',
      'UMH.D',
      'USB.A',
      'USB.H',
      'USB.P',
      'USB.Q',
      'USB.R',
      'USB.S',
      'VNO.L',
      'VNO.M',
      'VNO.N',
      'VNO.O',
      'VOYA.B',
      'WAL.A',
      'WBS.F',
      'WBS.G',
      'WCC.A',
      'WFC.A',
      'WFC.C',
      'WFC.D',
      'WFC.L',
      'WFC.Q',
      'WFC.R',
      'WFC.Y',
      'WFC.Z',
      'XFLT.A',
      'YCBD.A',
    ];
    const _SYMBOLS_WITH_UNDERSCORE = [
      'AACT.U',
      'ADEX.U',
      'ADRA.U',
      'APN.U',
      'ATEK.U',
      'BITE.U',
      'BSAQ.U',
      'DMYY.U',
      'DSAQ.U',
      'DSPK.U',
      'GIA.U',
      'HYAC.U',
      'ICNC.U',
      'INAQ.U',
      'PACI.U',
      'SBXC.U',
      'SCVX.U',
      'SLAC.U',
      'TWNI.U',
    ];
    this._SYMBOLS_WITH_HYPHEN = {};
    _SYMBOLS_WITH_HYPHEN.forEach((sym) => {
      this._SYMBOLS_WITH_HYPHEN[sym] = true;
    });
    this._SYMBOLS_WITH_UNDERSCORE = {};
    _SYMBOLS_WITH_UNDERSCORE.forEach((sym) => {
      this._SYMBOLS_WITH_UNDERSCORE[sym] = true;
    });
  }

  normalizeSymbols = (symbols) => {
    return symbols.map((s) => this.normalizeSymbol(s)).join(',');
  };

  normalizeSymbol = (symbol) => {
    if (this._SYMBOLS_WITH_HYPHEN[symbol]) {
      return replaceAll(symbol, '.', '-');
    }
    if (this._SYMBOLS_WITH_UNDERSCORE[symbol]) {
      return replaceAll(symbol, '.', '_');
    }
    return symbol;
  };

  unnormalizeSymbol = (_params_symbol) => {
    let symbol = replaceAll(_params_symbol, '-', '.');
    symbol = replaceAll(symbol, '_', '.');
    return symbol;
  };

  unnormalizeSymbolsInResponse = (responseData) => {
    let unnormalized = {};
    Object.keys(responseData).forEach((symbol) => {
      const d = responseData[symbol];
      unnormalized[this.unnormalizeSymbol(symbol)] = {
        quote: {
          ...d.quote,
          symbol: d[this.unnormalizeSymbol(d.quote.symbol)],
        },
      };
    });
    return unnormalized;
  };

  breakSymbolsIntoGroupsOf = (symbols, size) => {
    const group = [];
    for (let i = 0; i < symbols.length; i += size) group.push(symbols.slice(i, i + size));
    return group;
  };
}

const helpers = new _Helpers();

class ActiveSecuritiesTracker {
  constructor() {
    this.securitySymbols = [];
    this.tempSecuritySymbols = [];
    this.securitySymbolToIdLookup = {};

    this.activeSecuritiesPoll = setInterval(this.sendActiveSecurities, 1000 * 60 * 5);
  }

  sendActiveSecurities = async () => {
    const securityIds = activeSecuritiesTracker.getActiveSecurityIds();
    const newUrl = `${BASEURL}/api/v1/securities/price_stream`;
    const body = {
      security_ids: securityIds,
    };
    const response = await sendRequest('POST', [newUrl, body]);
    if (response?.status !== 200) {
      console.log('error recording active securities');
    }
    return response;
  };

  getActiveSecurities = () => {
    return filterArrayForDuplicates([...this.tempSecuritySymbols, ...this.securitySymbols]);
  };
  getActiveSecurityIds = () => {
    return this.getActiveSecurities().map((s) => this.securitySymbolToIdLookup[s.toLowerCase()]);
  };

  getIdFromSymbol = (s) => this.securitySymbolToIdLookup[s.toLowerCase()];

  addSecurities = async (securities) => {
    securities.forEach((s) => this._addSecurity(s));
    const symbols = securities.map((s) => s.symbol);
    await this.sendActiveSecurities();
    priceUpdater.runBatchedPriceUpdates(symbols);
  };

  addTempSecurities = async (securities) => {
    securities.forEach((s) => this._addTempSecuritySymbols(s));
    const symbols = securities.map((s) => s.symbol);
    await this.sendActiveSecurities();
    priceUpdater.runBatchedPriceUpdates(symbols);
  };

  removeSecurities = (symbols) => {
    symbols.forEach((s) => this._removeSecurity(s));
  };

  removeTempSecurities = (symbols) => {
    symbols.forEach((s) => this._removeTempSecurity(s));
  };

  _addSecurity = ({ id, symbol }) => {
    if (this.securitySymbols.indexOf(symbol) < 0) {
      this.securitySymbols.push(symbol);
    }
    this.securitySymbolToIdLookup[symbol.toLowerCase()] = id;
  };

  _addTempSecuritySymbols = ({ id, symbol }) => {
    if (this.tempSecuritySymbols.indexOf(symbol) < 0) {
      this.tempSecuritySymbols.push(symbol);
    }
    this.securitySymbolToIdLookup[symbol.toLowerCase()] = id;
  };

  _removeSecurity = (symbol) => {
    this.securitySymbols = this.securitySymbols.filter((s) => s !== symbol);
  };

  _removeTempSecurity = (symbol) => {
    this.tempSecuritySymbols = this.tempSecuritySymbols.filter((s) => s !== symbol);
  };
}

export const activeSecuritiesTracker = new ActiveSecuritiesTracker();

class PriceUpdater {
  constructor() {
    this._maxSymbolsInRequest = 25;
    this.afterPollExecuteCallback = null;
  }

  updatePriceUpdateCallback = (cb) => {
    this.afterPollExecuteCallback = cb;
  };

  runBatchedPriceUpdates = (symbols) => {
    if (this.afterPollExecuteCallback === null) {
      // this happens if you add pricing before the app is fully initialized, the dependency is that market trading hours has not yet been received, pricing will auto retrieve when initialized, it's safe to ignore this warning
      // console.warn('could not yet run get prices', symbols);
      return null;
    }
    if (!symbols || symbols.length < 1) return null;
    const groups = helpers.breakSymbolsIntoGroupsOf(symbols, this._maxSymbolsInRequest);
    groups.forEach((batchedSymbols) => this.getPriceUpdates(batchedSymbols));
  };

  getPriceUpdates = async (symbols) => {
    if (!symbols || symbols.length < 1) return null;
    if (this.afterPollExecuteCallback === null) {
      // this happens if you add pricing before the app is fully initialized, the dependency is that market trading hours has not yet been received, pricing will auto retrieve when initialized, it's safe to ignore this warning
      // console.warn('could not yet run get prices', symbols);
      return null;
    }

    const responseData = await this._fetchPriceDataFromService(symbols);
    if (responseData === null) return;

    const priceUpdates = this._handleFetchPriceDataResponse(responseData);
    this.afterPollExecuteCallback(priceUpdates);
  };

  _fetchPriceDataFromService = async (symbols) => {
    try {
      // const normalizedSymbolsCSV = helpers.normalizeSymbols(symbols);
      const securityIdsCSV = activeSecuritiesTracker.getActiveSecurityIds().join(',');
      const newUrl = `${BASEURL}/api/v1/securities/price_stream?security_ids=${securityIdsCSV}`;
      const { status, data } = await sendRequest('get', [newUrl]);
      if (status === 200) {
        return data;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  _handleFetchPriceDataResponse = (securities) => {
    if (!securities) return null;

    const priceUpdates = [];
    securities.forEach((sec) => {
      try {
        const {
          symbol,
          time: as_of_time,
          last_price: price,
          price_change: change,
          price_change_percentage: changePercent,
          previous_close_price: previousClose,

          // latestSource,
          // extendedChange,
          // extendedChangePercent,
          // extendedPrice,
          // extendedPriceTime,
          // close,
          // open,
        } = sec;

        const newPriceData = {
          id: activeSecuritiesTracker.getIdFromSymbol(symbol),
          debug_symbol: symbol,
          price,
          priceAsOf: as_of_time,

          change,
          percentChange: changePercent,

          // open,
          // close,
          extendedPrice: null,
          extendedChange: null,
          extendedChangePercent: null,
          extendedAsOf: null,
          previousClose,
        };

        if (!isNaN(price)) {
          priceUpdates.push(newPriceData);
        }
      } catch (e) {
        return null;
      }
    });
    return priceUpdates;
  };
}

export const priceUpdater = new PriceUpdater();

class PricingPoller {
  constructor() {
    this.pollRef = null;

    this.POLLING_RATE_MS = 5 * 1000;
    this.PAPER_POLLING_RATE_MS = 30 * 1000;

    this.EXTENDED_MARKET_POLLING_RATE_MS = 60 * 1000;
    this.MARKET_CLOSED_POLLING_RATE_MS = 60 * 15 * 1000;

    this.pollingRate = this.POLLING_RATE_MS;

    this.isLiveTrading = null;
    this.isMarketOpen = null;
    this.isInExtendedTrading = null;
  }

  setIsLiveTrading = (v) => {
    if (v !== this.isLiveTrading) {
      this.isLiveTrading = v;
      this.onLiveTradingChange(v);
    }
  };

  setIsMarketOpen = (v) => {
    if (v !== this.isMarketOpen) {
      this.isMarketOpen = v;
      this.onMarketOpenChange(v);
    }
  };

  setIsInExtendedTrading = (v) => {
    if (v !== this.isInExtendedTrading) {
      this.isInExtendedTrading = v;
      this.onInExtendedTradingChange(v);
    }
  };

  onMarketOpenChange = () => {
    this.recalculatePollingRate();
  };

  onInExtendedTradingChange = () => {
    this.recalculatePollingRate();
  };

  onLiveTradingChange = () => {
    this.recalculatePollingRate();
  };

  recalculatePollingRate = () => {
    let newPollingRate;

    if (this.isLiveTrading) {
      if (this.isMarketOpen) {
        newPollingRate = this.POLLING_RATE_MS;
      } else {
        if (this.isInExtendedTrading) {
          newPollingRate = this.EXTENDED_MARKET_POLLING_RATE_MS;
        } else {
          newPollingRate = this.MARKET_CLOSED_POLLING_RATE_MS;
        }
      }
    } else {
      if (this.isMarketOpen) {
        newPollingRate = this.PAPER_POLLING_RATE_MS;
      } else {
        newPollingRate = null;
      }
    }

    if (this.pollingRate !== newPollingRate) {
      this.setPollingRate(newPollingRate);
    }
  };

  setPollingRate = (rate) => {
    this.pollingRate = rate;
    this.cancelPoll();
    this._createPoll();
  };

  initPoll = (cb) => {
    priceUpdater.updatePriceUpdateCallback(cb);
    this._createPoll();
  };

  cancelPoll = () => {
    const poll = this.pollRef;
    if (poll) clearInterval(poll);
  };

  _createPoll = () => {
    // console.log('polling rate', this.pollingRate);
    if (this.pollingRate === null) return;

    this._onPollExecute();
    this.cancelPoll(); // clear any active polls first
    this.pollRef = setInterval(this._onPollExecute, this.pollingRate);
  };

  _onPollExecute = () => {
    const symbols = activeSecuritiesTracker.getActiveSecurities();
    priceUpdater.runBatchedPriceUpdates(symbols);
  };
}

export const LivePricePollingManager = new PricingPoller();
